<template>
    <main class="swcluster-main" id="swcluster-datahub">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <CommonBreadcrumb :show-title="true">
                    <template v-slot:title-img>
                        <div class="title-img">
                            <img src="@/assets/images/datahub/datahub_logo_1.svg" alt="한국정보통신기술협회 로고" width="177" height="100" />
                        </div>
                    </template>
                </CommonBreadcrumb>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-contents -->
            <div class="page-contents">
                <div class="board-view-container">
                    <header class="board-header">
                        <h3 class="board-title">데이터 및 SW 품질 컨설팅 신청</h3>
                    </header>
                    <div class="board-body">
                        <h4 class="view-title">컨설팅</h4>
                        <div class="view-input first-input">
                            <h5>컨설팅 목적</h5>
                            <div class="form-field">
                                <!-- form-row -->
                                <div class="form-row">
                                    <label class="form-label">컨설팅 목적 <span class="text-red">*</span></label>
                                    <span v-if="isView" type="text" class="form-span">{{ etcParams.consultingNm ? etcParams.consultingNm : '-' }}</span>
                                    <input
                                        v-else
                                        v-model.trim="etcParams.consultingNm"
                                        type="text"
                                        class="form-input"
                                        placeholder="데이터 및 SW 품질 컨설팅을 통해 해결하고 싶은 사항" />
                                </div>
                            </div>
                        </div>

                        <template v-for="(form, index) in applyForms" :key="index">
                            <h4 v-if="form.bundleTitle !== null" class="view-title">{{ form.bundleTitle }}</h4>

                            <div class="view-input">
                                <h5>{{ form.title }}</h5>

                                <template v-for="(param, idx) in form.params" :key="`${index}-${idx}`">
                                    <template v-if="param.key === 'address'">
                                        <div class="form-field">
                                            <!-- form-row -->
                                            <div class="form-row">
                                                <label class="form-label">{{ param.title }} <span class="text-red">*</span></label>
                                                <div class="form-group">
                                                    <span v-if="isView" type="text" class="form-span" style="width: 30%; margin-right: 8px">{{
                                                        etcParams.postCode ? etcParams.postCode : '-'
                                                    }}</span>
                                                    <input
                                                        v-else
                                                        v-model="etcParams.postCode"
                                                        type="text"
                                                        class="form-input"
                                                        placeholder="우편번호"
                                                        style="width: 20%; margin-right: 8px"
                                                        disabled />

                                                    <span v-if="isView" type="text" class="form-span" style="width: 70%; margin-right: 8px">{{
                                                        etcParams.address ? etcParams.address : '-'
                                                    }}</span>
                                                    <input
                                                        v-else
                                                        v-model.trim="etcParams.address"
                                                        type="text"
                                                        class="form-input"
                                                        placeholder="주소"
                                                        style="width: 70%; margin-right: 8px"
                                                        disabled />

                                                    <button
                                                        v-if="!isView"
                                                        class="dev-btn-action dev-btn-primary"
                                                        style="width: 10%"
                                                        @click="toggles.post = true">
                                                        <span class="text">주소</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-field">
                                            <!-- form-row -->
                                            <div class="form-row">
                                                <label class="form-label">상세주소</label>

                                                <span v-if="isView" type="text" class="form-span">{{ etcParams.detail ? etcParams.detail : '-' }}</span>
                                                <input v-else v-model.trim="etcParams.detail" type="text" class="form-input" placeholder="상세주소" />
                                            </div>
                                        </div>
                                    </template>
                                    <div v-else class="form-field">
                                        <!-- form-row -->
                                        <div class="form-row">
                                            <label class="form-label">{{ param.title }} <span v-if="param.required" class="text-red">*</span></label>
                                            <span v-if="isView" type="text" class="form-span">{{ param.model ? param.model : '-' }}</span>
                                            <input
                                                v-else
                                                v-model.trim="param.model"
                                                type="text"
                                                class="form-input"
                                                :placeholder="getPlaceholder(param.title)" />
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>

                        <template v-if="isView">
                            <h4 class="view-title">컨설팅 관리</h4>
                            <div class="view-input">
                                <div class="form-field">
                                    <!-- form-row -->
                                    <div class="form-row">
                                        <label class="form-label">신청 상태</label>
                                        <span
                                            class="form-span"
                                            :class="{
                                                'text-blue': managerParams.consultingStDcd === consultingStDcds.approve,
                                                'text-red': managerParams.consultingStDcd === consultingStDcds.reject,
                                                'text-green': managerParams.consultingStDcd === consultingStDcds.complete,
                                            }">
                                            {{ getDomainCodeNm(managerParams.consultingStDcd) }}
                                        </span>
                                    </div>
                                </div>

                                <div class="form-field">
                                    <!-- form-row -->
                                    <div class="form-row">
                                        <label class="form-label">신청 일시</label>
                                        <span class="form-span">{{ timestampToDateFormat(managerParams.createDt, 'yyyy.MM.dd hh:mm') }}</span>
                                    </div>
                                </div>

                                <div class="form-field">
                                    <!-- form-row -->
                                    <div class="form-row" style="align-items: baseline">
                                        <label class="form-label">메모</label>
                                        <textarea v-model.trim="managerParams.consultingMemo" rows="10" class="form-input" />
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <div v-if="!toggles.post" class="page-bottom bottom-fixed">
            <div>
                <div class="bottom-row-container bottom-component">
                    <div v-if="!isView" class="bottom-side">
                        <button class="btn-action btn-primary" @click="insertConsulting"><span class="text">신청완료</span></button>
                    </div>

                    <template v-else>
                        <div class="bottom-side">
                            <button class="btn-action" @click="goStatus">
                                <span class="text">목록</span>
                            </button>
                        </div>
                        <div
                            v-if="consultingStDcds.approve !== managerParams.consultingStDcd && consultingStDcds.complete !== managerParams.consultingStDcd"
                            class="bottom-side">
                            <button
                                class="btn-action btn-blue"
                                @click="updateConsulting(consultingNo, consultingStDcds.approve, managerParams.consultingMemo, updateCallback)">
                                <span class="text">승인</span>
                            </button>
                        </div>
                        <div v-if="consultingStDcds.reject !== managerParams.consultingStDcd" class="bottom-side">
                            <button
                                class="btn-action btn-red"
                                @click="updateConsulting(consultingNo, consultingStDcds.reject, managerParams.consultingMemo, updateCallback)">
                                <span class="text">반려</span>
                            </button>
                        </div>
                        <div v-if="consultingStDcds.approve === managerParams.consultingStDcd" class="bottom-side">
                            <button
                                class="btn-action btn-green"
                                @click="updateConsulting(consultingNo, consultingStDcds.complete, managerParams.consultingMemo, updateCallback)">
                                <span class="text">완료</span>
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <!-- //page-bottom -->
        <CommonPostCode v-if="toggles.post" v-model:toggle="toggles.post" @selected="selectAddress" />
    </main>
</template>

<script>
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';

import {computed, reactive, ref, watch} from 'vue';
import CommonPostCode from '@/components/common/CommonPostCode';
import {getAuthSession} from '@/assets/js/modules/auth/module';
import {useRoute, useRouter} from 'vue-router';
import ApiService from '@/assets/js/api.service';
import {getItem, isSuccess, lengthCheck, timestampToDateFormat} from '@/assets/js/common.utils';
import {consultingStDcds, updateConsulting} from '@/assets/js/modules/consulting/module';
import {hideLoading, showLoading} from '@/assets/js/common.alert';
import {getDomainCodeNm} from '@/assets/js/modules/common/module';

export default {
    name: 'ConsultingApply',
    components: {CommonPostCode, CommonBreadcrumb},
    setup: function () {
        const route = useRoute();
        const router = useRouter();

        const session = computed(getAuthSession);
        const consultingNo = computed(() => (route.params.consultingNo ? parseInt(route.params.consultingNo) : 0));

        // 추후 router 처리
        const isView = computed(() => route.name === 'ConsultingView');

        const toggles = reactive({
            post: false,
        });

        const managerParams = reactive({
            consultingMemo: '',
            consultingStDcd: '',
            createDt: 0,
        });

        const etcParams = reactive({
            consultingNm: '',
            address: '',
            postCode: '',
            detail: '',
        });

        const applyForms = ref([
            {
                bundleTitle: '신청 기업 정보',
                title: '기본 정보',
                params: [
                    {
                        title: '기관명',
                        key: 'company',
                        model: '',
                        required: true,
                        validateMsg: '기관명을 입력해주세요.',
                    },
                    {
                        title: '사업자등록번호',
                        key: 'businessRegistrationNumber',
                        model: '',
                        required: true,
                        validateMsg: '사업자등록번호를 입력해주세요.',
                    },
                    {
                        title: '주소',
                        key: 'address',
                        model: '',
                    },
                    // {
                    //     title: '상세주소',
                    //     key: 'detailedAddress',
                    //     model: '',
                    // },
                    {
                        title: '홈페이지',
                        key: 'companyHomePage',
                        model: '',
                    },
                ],
            },
            {
                bundleTitle: null,
                title: '대표자 정보',
                params: [
                    {
                        title: '대표자',
                        key: 'representative',
                        model: '',
                        required: true,
                        validateMsg: '대표자명을 입력해주세요.',
                    },
                    {
                        title: '대표 연락처',
                        key: 'representativeContact',
                        model: '',
                        required: true,
                        validateMsg: '대표 연락처를 입력해주세요.',
                    },
                    {
                        title: '대표 이메일',
                        key: 'representativeEmail',
                        model: '',
                        required: true,
                        validateMsg: '대표 이메일을 입력해주세요.',
                    },
                ],
            },
            {
                bundleTitle: null,
                title: '담당자 정보',
                params: [
                    {
                        title: '담당자',
                        key: 'manager',
                        model: '',
                        required: true,
                        validateMsg: '담당자명을 입력해주세요.',
                    },
                    {
                        title: '담당자 소속부서',
                        key: 'managerDepartment',
                        model: '',
                    },
                    {
                        title: '담당자 직급',
                        key: 'managerPosition',
                        model: '',
                    },
                    {
                        title: '담당자 연락처',
                        key: 'managerContact',
                        model: '',
                        required: true,
                        validateMsg: '담당자 연락처를 입력해주세요.',
                    },
                    {
                        title: '담당자 이메일',
                        key: 'managerContact',
                        model: '',
                        required: true,
                        validateMsg: '담당자 이메일을 입력해주세요.',
                    },
                ],
            },

            {
                bundleTitle: '컨설팅 의뢰 항목',
                title: '제품 정보',
                params: [
                    {
                        title: '제품명',
                        key: 'product',
                        model: '',
                        required: true,
                        validateMsg: '제품명을 입력해주세요.',
                    },
                    {
                        title: '주요 내용',
                        key: 'productContents',
                        model: '',
                        required: true,
                        validateMsg: '제품 주요내용을 입력해주세요.',
                    },
                    {
                        title: '시스템 구성',
                        key: 'productSystem',
                        model: '',
                    },
                ],
            },

            {
                bundleTitle: null,
                title: '데이터 정보',
                params: [
                    {
                        title: '데이터명',
                        key: 'data',
                        model: '',
                        required: true,
                        validateMsg: '데이터명을 입력해주세요.',
                    },
                    {
                        title: '데이터 건 수',
                        key: 'dataCount',
                        model: '',
                        required: true,
                        validateMsg: '데이터 건수를 입력해주세요.',
                    },
                    {
                        title: '데이터 구조',
                        key: 'dataStructure',
                        model: '',
                        required: true,
                        validateMsg: '데이터 구조를 입력해주세요.',
                    },

                    {
                        title: '수집 방법',
                        key: 'collectionMethod',
                        model: '',
                    },
                    {
                        title: '제공처',
                        key: 'servicePlace',
                        model: '',
                    },
                ],
            },
        ]);

        const selectAddress = a => {
            etcParams.address = a.address;
            etcParams.postCode = a.zonecode;
        };

        const goStatus = e => {
            if (e.ctrlKey) {
                window.open('/consulting/status');
            } else {
                router.push({name: 'ConsultingStatus'});
            }
        };

        const getPlaceholder = title => {
            if (title === '데이터 구조') {
                return title + '(예: xml, api, csv 등)';
            }
            return title;
        };

        const validateParams = (params, requiredParams) => {
            const msg = [];
            requiredParams.forEach(x => {
                if (!params[x.key]) {
                    msg.push(x.msg);
                }
            });

            if (msg.length > 0) {
                alert(msg.join('\n'));
            }
            return msg.length === 0;
        };

        const insertConsulting = () => {
            let params = {};
            const requiredParams = [
                {
                    key: 'consultingNm',
                    msg: '컨설팅 목적을 입력해주세요.',
                },
            ];

            applyForms.value.forEach(form => {
                form.params.forEach(param => {
                    params[param.key] = param.model;
                    if (param.required) {
                        requiredParams.push({
                            key: param.key,
                            msg: param.validateMsg,
                        });
                    }
                });
            });
            //
            params = {...params, ...etcParams};
            requiredParams.push({
                key: 'address',
                msg: '주소를 입력해주세요.',
            });

            if (validateParams(params, requiredParams)) {
                if (confirm('데이터 및 SW 품질 컨설팅을 신청하시겠습니까?')) {
                    showLoading();
                    ApiService.post('/v1/consultings', {
                        consultingKey: params.representativeContact,
                        consultingNm: params.consultingNm,
                        consultingTarget: params.company,
                        consultingStDcd: consultingStDcds.apply,
                        consultingJson: JSON.stringify(params),
                    })
                        .then(res => {
                            if (isSuccess(res)) {
                                alert('데이터 및 SW 품질 컨설팅이 신청되었습니다.');
                                router.push({name: 'Consulting'});
                            } else {
                                alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                            }
                            hideLoading();
                        })
                        .catch(e => {
                            console.error(e);
                            hideLoading();
                            alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                        });
                }
            }
        };

        const setFormParams = params => {
            applyForms.value.forEach(form => {
                form.params.forEach(p => {
                    if (params[p.key]) {
                        p.model = params[p.key];
                    }
                });
            });

            // etc params set
            Object.keys(etcParams).forEach(key => {
                if (params[key]) {
                    etcParams[key] = params[key];
                }
            });
        };

        const updateCallback = consultingStDcd => {
            managerParams.consultingStDcd = consultingStDcd;
        };

        const getConsulting = () => {
            ApiService.get('/v1/consultings', consultingNo.value)
                .then(res => {
                    if (lengthCheck(res)) {
                        const item = getItem(res);
                        console.log(item);
                        setFormParams(JSON.parse(item.consultingJson));

                        Object.keys(managerParams).forEach(key => {
                            if (item[key]) {
                                managerParams[key] = item[key];
                            }
                        });
                    }
                })
                .catch(e => {
                    console.error(e);
                });
        };

        if (isView.value) {
            if (!session.value.manager) {
                router.push({name: 'Main'});
            } else if (consultingNo.value === 0) {
                router.push({name: 'Main'});
            } else {
                getConsulting();
            }
        }

        // 사업자 등록번호 자동완성
        const onlyNumberRex = /[^0-9]/g;
        const phoneRex = /^(\d{2,3})(\d{3,4})(\d{4})$/;
        const addAutoHyphen = str => {
            str = str.replace(onlyNumberRex, '');
            let tmp = '';
            if (str.length < 4) {
                return str;
            } else if (str.length < 6) {
                tmp += str.substr(0, 3);
                tmp += '-';
                tmp += str.substr(3);
                return tmp;
            } else {
                tmp += str.substr(0, 3);
                tmp += '-';
                tmp += str.substr(3, 2);
                tmp += '-';
                if (str.length > 10) {
                    tmp += str.substring(5, 10);
                } else {
                    tmp += str.substr(5);
                }
                return tmp;
            }
        };

        const addAutoHyphen2 = str => {
            str = str.replace(onlyNumberRex, '');
            if (str.length > 11) {
                str = str.substring(0, 11);
            }
            return str.replace(phoneRex, `$1-$2-$3`);
        };

        watch(
            () => applyForms.value[0].params[1].model,
            () => {
                applyForms.value[0].params[1].model = addAutoHyphen(applyForms.value[0].params[1].model);
            },
        );

        watch(
            () => applyForms.value[1].params[1].model,
            () => {
                applyForms.value[1].params[1].model = addAutoHyphen2(applyForms.value[1].params[1].model);
            },
        );

        watch(
            () => applyForms.value[2].params[3].model,
            () => {
                applyForms.value[2].params[3].model = addAutoHyphen2(applyForms.value[2].params[3].model);
            },
        );

        // phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

        return {
            consultingNo,
            isView,
            etcParams,
            applyForms,
            managerParams,
            toggles,
            selectAddress,
            getPlaceholder,
            goStatus,
            insertConsulting,
            updateConsulting,
            updateCallback,
            getDomainCodeNm,
            timestampToDateFormat,
            consultingStDcds,
        };
    },
};
</script>

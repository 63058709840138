<template>
    <!--    <div class="page-header header-divider">-->
    <!--        <div class="header-component">-->
    <nav class="breadcrumb" :class="breadcrumbClasses">
        <ol class="breadcrumb-wrapper">
            <li class="breadcrumb-item"><router-link :to="{name: 'Main'}">홈</router-link></li>
            <li v-for="(menu, index) in currentMenus" class="breadcrumb-item" :key="index">
                <router-link v-if="menu.uri" :to="menu.uri" :class="{'is-active': index === currentMenus.length - 1}">{{ menu.name }}</router-link>
                <a v-else href="javascript:" :class="{'is-active': index === currentMenus.length - 1}">{{ menu.name }}</a>
            </li>
        </ol>
    </nav>
    <h2 v-if="showTitle && currentMenus.length > 0" class="page-title">
        {{ currentMenus[currentMenus.length - 1].name }}
        <slot name="title-img"></slot>
    </h2>
    <!--        </div>-->
    <!--    </div>-->
</template>

<script>
import {useRoute} from 'vue-router';
import {computed} from 'vue';
import {useStore} from 'vuex';

export default {
    name: 'CommonBreadcrumb',
    props: {
        showTitle: Boolean,
        breadcrumbClasses: Array,
    },
    setup() {
        const store = useStore();
        const route = useRoute();

        const currentMenus = computed(() => {
            const parents = store.state.auth.menus;
            const path = route.path;
            const result = [];
            for (let i = 0; i < parents.length; i++) {
                if (parents[i].uri && path.indexOf(parents[i].uri) === 0) {
                    result.push({name: parents[i].name, uri: parents[i].uri});
                    break;
                } else {
                    const children = parents[i].children;
                    for (let j = 0; j < children.length; j++) {
                        if (children[j].uri && path.indexOf(children[j].uri) === 0) {
                            result.push({name: parents[i].name});
                            result.push({name: children[j].name, uri: children[j].uri});
                            break;
                        }
                    }
                }
            }
            return result;
        });

        return {
            currentMenus,
        };
    },
};
</script>

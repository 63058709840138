<template>
    <div class="layer-container">
        <div class="layer">
            <div class="layer-body" style="width: 500px; max-height: 650px; overflow-y: auto">
                <VueDaumPostcode @complete="selectAddress" />
            </div>
            <div class="layer-actions">
                <button class="btn-action btn-outline" @click="close"><span class="text">취소</span></button>
            </div>
        </div>
    </div>
</template>

<script>
import {VueDaumPostcode} from 'vue-daum-postcode';

export default {
    name: 'CommonPostCode',
    components: {
        VueDaumPostcode,
    },
    props: {
        toggle: Boolean,
    },
    emits: ['update:toggle', 'selected'],
    setup(props, {emit}) {
        const close = () => {
            emit('update:toggle', false);
        };

        const selectAddress = a => {
            if (a) emit('selected', a);
            close();
        };

        // const loadedPost = () => {
        //     setTimeout(() => {
        //         isLoaded.value = true;
        //     }, 500);
        // };

        return {
            close,
            selectAddress,
        };
    },
};
</script>
